import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

function Nav({ match, name }) {
    return (
        <aside className="scrollbar">
            <nav>
                <ul>
                    <li>
                        <NavLink
                            className={classNames({ 'no-answer': !name.includes('人员信息') }, 'nav-item')}
                            to={`${match.url}/人员信息采集`}
                        >
                            <div className="icon bg-nav bg-nav-人员信息采集" />
                            人员信息采集
                        </NavLink>
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-专项附加扣除信息采集" />
                        专项附加扣除信息采集
                    </li>
                    <li>
                        <NavLink
                            className={classNames({ 'no-answer': '正常工资薪金所得' !== name }, 'nav-item')}
                            to={`${match.url}/综合所得申报`}
                        >
                            <div className="icon bg-nav bg-nav-综合所得申报" />
                            综合所得申报
                        </NavLink>
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-分类所得申报" />
                        分类所得申报
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-非居民所得申报" />
                        非居民所得申报
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-限售股所得申报" />
                        限售股所得申报
                    </li>
                    <li className="nav-item sanjiao no-answer">
                        <div className="icon bg-nav bg-nav-税款缴纳" />
                        税款缴纳
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-退付手续费核对" />
                        退付手续费核对
                    </li>
                    <li className="nav-item sanjiao no-answer">
                        <div className="icon bg-nav bg-nav-优惠备案" />
                        优惠备案
                    </li>
                    <li className="nav-item sanjiao no-answer">
                        <div className="icon bg-nav bg-nav-查询统计" />
                        查询统计
                    </li>
                    <li className="nav-item no-answer">
                        <div className="icon bg-nav bg-nav-系统设置" />
                        系统设置
                    </li>
                </ul>
            </nav>
        </aside>
    )
}

export default Nav
